import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { Toast } from 'vant'
import "vue-fullpage.js/dist/style.css";
// import "./fullpage.scrollHorizontally.min"
import VueFullPage from "vue-fullpage.js"
import VueLazyload from "vue-lazyload";
import loadimage from "./assets/loading_holder.png"
import errorimage from "./assets/load_img.jpg"
createApp(App).use(router).use(VueLazyload,{
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
  }).use(VueFullPage).mount('#app')
