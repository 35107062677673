import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {path:"/",name:"HomeIndex",meta:{requiresAuth:false},component:() => import("../views/HomeIndex.vue")},
    {path:"/PhilosophyIndex",name:"PhilosophyIndex",meta:{requiresAuth:false},component:() => import("../views/PhilosophyIndex.vue")},
    {path:"/ScienceIndex",name:"ScienceIndex",meta:{requiresAuth:false},component:() => import("../views/ScienceIndex.vue")},
    {path:"/IngredientsIndex",name:"IngredientsIndex",meta:{requiresAuth:false},component:() => import("../views/IngredientsIndex.vue")}, //四项单独 精准成分
    {path:"/ResearchInnovation4partIndex",name:"ResearchInnovation4partIndex",meta:{requiresAuth:false},component:() => import("../views/ResearchInnovation4partIndex.vue")}, //四项
    {path:"/ProductCateIndex",name:"ProductCateIndex",meta:{requiresAuth:false},component:() => import("../views/ProductCateIndex.vue")},
    {path:"/ProductSingleCateIndex",name:"ProductSingleCateIndex",meta:{requiresAuth:false},component:() => import("../views/ProductSingleCateIndex.vue")},
    {path:"/ProductDetailIndex",name:"ProductDetailIndex",meta:{requiresAuth:false},component:() => import("../views/ProductDetailIndex.vue")},
    // {path:"/swipertest",name:"swipertest",meta:{requiresAuth:false},component:() => import("../views/mobile/swipertest.vue")},
  
]
const router = createRouter({
    history:createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 始终滚动到顶部
        return { top: 0 }
      },
})
router.beforeEach((to,from,next) => {
    //重定向 确保微信授权后地址可以被正确访问
    if(location.search && (location.href.indexOf("/#/" == -1)) && location.pathname){
        console.log("before each href",location.origin + location.pathname + "#/" + location.search);
        location.href = location.origin + location.pathname + "#/" + location.search;
    }else{
        next()
    }
    //可选第三个参数next 确保在任何给定的导航守卫中都被严格调用一次
    if(to.meta.requiresAuth && !auth.isLoggedIn){
        //路由需要授权 检查是否登录 
        //如果没有 则重定向到登录
        return {
            path:"/login",
            //保存我们所在位置 以便以后再来
            query:{redirect:to.fullPath}
        }
    }
})
router.afterEach((to,from) => {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    console.log("toDepth",toDepth,"fromDepth",fromDepth,to.path,to.path.split('/'));
    to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    console.log("url aftereach",location.href,location.origin,location.search);
})
export default router;